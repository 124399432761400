import Stake0Window from "./components/Stake0Window";
import Stake1Window from "./components/Stake1Window";
import Stake2Window from "./components/Stake2Window";

function Staking() {
  return (
    <>
      <Stake0Window />
      <Stake1Window />
      <Stake2Window />
    </>
  );
}

export default Staking;
